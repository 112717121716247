import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main'
import LoginIndex from '../views/login/LoginIndex'
import RegisterIndex from '../views/login/RegisterIndex'
import ForgotPasswordIndex from '../views/login/ForgotPasswordIndex'
import ResetPasswordIndex from '../views/login/ResetPasswordIndex'

import AccountMain from '../views/AccountMain'
import OverviewIndex from '../views/dashboard/OverviewIndex'
import TeamIndex from '../views/dashboard/TeamIndex'
import MyActivitiesIndex from '../views/tracking/MyActivitiesIndex'
import ReviewAppsIndex from '../views/tracking/ReviewAppsIndex'
import ScreenshotsIndex from '../views/tracking/ScreenshotsIndex'
import TimelapseVideosIndex from '../views/tracking/TimelapseVideosIndex'
import TimesheetIndex from '../views/tracking/TimesheetIndex'
import ManuelTimeEntriesIndex from '../views/tracking/ManuelTimeEntriesIndex'

import CustomersIndex from '../views/project/CustomersIndex'
import ProjectsIndex from '../views/project/ProjectsIndex'
import TasksIndex from '../views/project/TasksIndex'

import UpdateInvoiceIndex from '../views/costmanagement/UpdateInvoiceIndex'
import InvoicesIndex from '../views/costmanagement/InvoicesIndex'

import ProductivityIndex from '../views/insights/ProductivityIndex'
import WorkTimesIndex from '../views/insights/WorkTimesIndex'
import AppsSummaryIndex from '../views/insights/AppsSummaryIndex'
import TaskInsightsIndex from '../views/insights/TaskInsightsIndex'
import WorkNotesIndex from '../views/insights/WorkNotesIndex'

import PayrollCalculatorIndex from '../views/costmanagement/PayrollCalculatorIndex'

import LeaveRequestsIndex from '../views/leavemanagement/LeaveRequestsIndex'
import LeaveRightsIndex from '../views/leavemanagement/LeaveRightsIndex'

import CreateOrganizationIndex from '../views/organization/CreateOrganizationIndex'
import EmployeesIndex from '../views/organization/EmployeesIndex'
import OrganizationSettingsIndex from '../views/organization/OrganizationSettingsIndex'

import SettingsIndex from '../views/account/SettingsIndex'
import BillingIndex from '../views/account/BillingIndex'
import AffiliateIndex from '../views/account/AffiliateIndex'
import StartSubscriptionIndex from '../views/account/StartSubscriptionIndex'
import SubscriptionOverIndex from '../views/account/SubscriptionOverIndex'

import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'login',
        component: LoginIndex,
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterIndex,
      },
      {
        path: 'forgot-password',
        name: 'forgotpassword',
        component: ForgotPasswordIndex,
      },
      {
        path: 'reset-password',
        name: 'resetpassword',
        component: ResetPasswordIndex,
      },
      {
        path: 'app',
        name: 'account',
        component: AccountMain,
        meta: {
            authGuard: true
        },
        children: [
          {
            path: 'dashboard/overview',
            name: 'overview',
            component: OverviewIndex
          },
          {
            path: 'dashboard/team',
            name: 'team',
            component: TeamIndex
          },
          {
            path: 'tracking/my-activities',
            name: 'my-activities',
            component: MyActivitiesIndex
          },
          {
            path: 'tracking/review-apps',
            name: 'review-apps',
            component: ReviewAppsIndex
          },
          {
            path: 'tracking/screenshots',
            name: 'screenshots',
            component: ScreenshotsIndex
          },
          {
            path: 'tracking/timelapse-videos',
            name: 'timelapse-videos',
            component: TimelapseVideosIndex
          },
          {
            path: 'tracking/timesheet',
            name: 'timesheet',
            component: TimesheetIndex
          },
          {
            path: 'tracking/manual-time-entries',
            name: 'manual-time-entries',
            component: ManuelTimeEntriesIndex
          },
          {
            path: 'project-management/customers',
            name: 'customers',
            component: CustomersIndex
          },
          {
            path: 'project-management/projects',
            name: 'projects',
            component: ProjectsIndex
          },
          {
            path: 'project-management/tasks',
            name: 'tasks',
            component: TasksIndex
          },
          {
            path: 'leave-management/leave-requests',
            name: 'leave-requests',
            component: LeaveRequestsIndex
          },
          {
            path: 'leave-management/leave-rights',
            name: 'leave-rights',
            component: LeaveRightsIndex
          },
          {
            path: 'cost-management/invoices',
            name: 'invoices',
            component: InvoicesIndex
          },
          {
            path: 'cost-management/invoices/invoice',
            name: 'update-invoice',
            component: UpdateInvoiceIndex
          },
          {
            path: 'cost-management/payroll-calculator',
            name: 'payroll-calculator',
            component: PayrollCalculatorIndex
          },
          {
            path: 'insights/productivity',
            name: 'productivity',
            component: ProductivityIndex
          },
          {
            path: 'insights/work-times',
            name: 'work-times',
            component: WorkTimesIndex
          },
          {
            path: 'insights/apps-summary',
            name: 'apps-summary',
            component: AppsSummaryIndex
          },
          {
            path: 'insights/task-insights',
            name: 'task-insights',
            component: TaskInsightsIndex
          },
          {
            path: 'insights/work-notes-insights',
            name: 'work-notes-insights',
            component: WorkNotesIndex
          },

          //WorkNotesIndex
          
          {
            path: 'organization/employees',
            name: 'employees',
            component: EmployeesIndex
          },
          {
            path: 'organization/settings',
            name: 'settings',
            component: OrganizationSettingsIndex
          },
          {
            path: 'organization/create-organization',
            name: 'create-organization',
            component: CreateOrganizationIndex
          },
          {
            path: 'account/settings',
            name: 'account-settings',
            component: SettingsIndex
          },
          {
            path: 'account/manage-subscription',
            name: 'manage-subscription',
            component: StartSubscriptionIndex
          },
          {
            path: 'account/subscription-over',
            name: 'subscription-over',
            component: SubscriptionOverIndex
          },
          {
            path: 'account/billing',
            name: 'billing',
            component: BillingIndex
          },
          {
            path: 'account/affiliate',
            name: 'affiliate',
            component: AffiliateIndex
          }
        ]
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    document.getElementById("app").scrollTo({top: 0, behavior: 'smooth'});
    store.commit('setValidationErrors', []);

    const loggedInUserDetail = !!localStorage.getItem("token");

    if(to.query.live)
    {
      localStorage.removeItem("token");
      next();
    }
    else
    {
      if (to.matched.some(m => m.meta.authGuard) && !loggedInUserDetail)
        next({ name: "login" });
      else if (['login','register','forgotpassword','resetpassword'].some(x => x == to.name) && loggedInUserDetail)
        next({ name: "my-activities" });
      else next();
    }

    
});

export default router