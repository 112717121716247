import axios from "axios";

const store = {
  state: () => ({
    productivity:null,
    workTimes:null,
    payroll:null,
    appsSummary:null,
    recentlyUsedApps:null,
    apps:null,
    websites:null,
    taskInsights:null,
    workNotesInsights:null,
  }),
  getters: {
    getProductivity(state) {
      return state.productivity;
    },
    getWorkTimes(state) {
      return state.workTimes;
    },
    getAppsSummary(state) {
      return state.appsSummary;
    },
    getRecentlyUsedApps(state) {
      return state.recentlyUsedApps;
    },
    getApps(state) {
      return state.apps;
    },
    getWebsites(state) {
      return state.websites;
    },
    getTaskInsights(state) {
      return state.taskInsights;
    },
    getWorkNotesInsights(state) {
      return state.workNotesInsights;
    },
  },
  mutations: {
    productivity(state, payload) {
      state.productivity = payload;
    },
    workTimes(state, payload) {
      state.workTimes = payload;
    },
    appsSummary(state, payload) {
      state.appsSummary = payload;
    },
    recentlyUsedApps(state, payload) {
      state.recentlyUsedApps = payload;
    },
    setApps(state, payload) {
      state.apps = payload;
    },
    setWebsites(state, payload) {
      state.websites = payload;
    },
    setTaskInsights(state, payload) {
      state.taskInsights = payload;
    },
    setWorkNotesInsights(state, payload) {
      state.workNotesInsights = payload;
    }
  },
  actions: {
    async productivity(store, payload) {
      var res = await axios.post("/insights/productivity", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("productivity", res.data.Data);
      return res.data.Data;
    },
    async productivityExport(store, payload) {
      var res = await axios.post("/insights/productivityExport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async workTimes(store, payload) {
      var res = await axios.post("/insights/workTimes", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("workTimes", res.data.Data);
      return res.data.Data;
    },
    async workTimesExport(store, payload) {
      var res = await axios.post("/insights/workTimesExport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async appsSummary(store, payload) {
      var res = await axios.post("/insights/appsSummary", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("appsSummary", res.data.Data);
      return res.data.Data;
    },
    async appsSummaryExport(store, payload) {
      var res = await axios.post("/insights/appsSummaryExport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async appsSummaryExportEmployees(store, payload) {
      var res = await axios.post("/insights/AppsSummaryExportEmployees", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async recentlyUsedApps(store, payload) {
      var res = await axios.post("/insights/recentlyUsedApps", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("recentlyUsedApps", res.data.Data);
      return res.data.Data;
    },
    async apps(store, payload) {
      var res = await axios.post("/insights/apps", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setApps", res.data.Data);
      return res.data.Data;
    },
    async websites(store, payload) {
      var res = await axios.post("/insights/websites", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setWebsites", res.data.Data);
      return res.data.Data;
    },
    async taskInsights(store, payload) {
      var res = await axios.post("/insights/tasks", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      for (let i = 0; i < res.data.Data.Items.length; i++) {
        const element = res.data.Data.Items[i];
        element.Details = {
          Loading:true,
          Items:[]
        };
      }

      store.commit("setTaskInsights", res.data.Data);
      return res.data.Data;
    },
    async workNotesInsights(store, payload) {
      var res = await axios.post("/insights/WorkNotes", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      for (let i = 0; i < res.data.Data.Items.length; i++) {
        const element = res.data.Data.Items[i];
        element.Details = {
          Loading:true,
          Items:[]
        };
      }

      store.commit("setWorkNotesInsights", res.data.Data);
      return res.data.Data;
    },
    async tasksInsightsExport(store, payload) {
      var res = await axios.post("/insights/TasksExport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async taskEmployeeInsights(store, payload) {

      var index = store.state.taskInsights.Items.findIndex(x => x.ID == payload.ProjectTaskId);
      if(index != -1)
      {
        store.state.taskInsights.Items[index].Loading = true;
      }

      var res = await axios.post("/insights/EmployeeTasks", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      if(index != -1)
      {
        store.state.taskInsights.Items[index].Details = res.data.Data;
      }

      return res.data.Data;
    },
    async workNoteEmployeeInsights(store, payload) {

      var index = store.state.workNotesInsights.Items.findIndex(x => x.ID == payload.WorkNote);
      if(index != -1)
      {
        store.state.workNotesInsights.Items[index].Loading = true;
      }

      var res = await axios.post("/insights/WorkTimes", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      payload.WorkNote = null;
      payload.WorkNoteNull = true;
      var res2 = await axios.post("/insights/WorkTimes", payload);
      if(res2 == null || res2.data.HasError)
      {
        return null;
      }

      for (let i = 0; i < res.data.Data.Items.length; i++) {
        const element = res.data.Data.Items[i];
        //console.log("element",element);
        const element2 = res2.data.Data.Items.find(x => x.ID == element.ID);
        //console.log("element2", element2);
        element.TotalMins = element2.Working;
      }

      res.data.Data.Items = res.data.Data.Items.filter(x => x.Working > 0);

      if(index != -1)
      {
        store.state.workNotesInsights.Items[index].Details = res.data.Data;
      }

      console.log("res.data.data.ITems", res.data.Data.Items);
      
      return res.data.Data;
    },
    
  }
}

export default store;