<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
    <portal to="action-buttons">
      <el-button type="primary" round icon="fas fa-shopping-basket" @click="$router.push({name:'manage-subscription'})">Manage subscription</el-button>
    </portal>

    <div class="activity-filter" v-if="subscriptionHistory && paymentMethods">
      <div class="filter-item purple" :class="activeTab == 'invoices' ? 'isactive' : ''" @click="activeTab = 'invoices'">
        <i class="fas fa-file-invoice"></i>
        <span>Invoices<b>{{ subscriptionHistory.TotalCount }}</b></span>
      </div>
      <div class="filter-item purple" :class="activeTab == 'paymentMethods' ? 'isactive' : ''" @click="activeTab = 'paymentMethods'" v-if="organization != null">
        <i class="fas fa-credit-card"></i>
        <span>Payment methods<b>{{ paymentMethods.Items.filter(x => x.Organization.Id == organization.ID).length }}</b></span>
      </div>
    </div>

    <div class="card p-5" v-if="!subscriptionHistory || !paymentMethods">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="subscriptionHistory && paymentMethods">
     <template v-if="activeTab == 'invoices'">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{subscriptionHistory.TotalCount}} invoice(s) here.</h3>
          </div>
        </div>

        <div class="activity-table">

          <NoData v-if="subscriptionHistory.Items.length == 0" />

          <el-table :data="subscriptionHistory.Items" v-if="subscriptionHistory.Items.length > 0">

            <el-table-column label="Invoice number">
              <template slot-scope="scope">
                <span>#{{ scope.row.InvoiceNo ?? " preparing" }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Organization" width="250">
              <template slot-scope="scope">
                <span>{{ scope.row.Organization.Title }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Date" width="220">
              <template slot-scope="scope">
                <span>{{ moment(scope.row.StartDate).format("DD.MM.yyyy HH:mm") }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Total" width="150">
              <template slot-scope="scope">
                <span class="badge"><template v-if="scope.row.Currency == 0">₺</template><template v-else>$</template> {{ scope.row.PaidPrice }}</span>
              </template>
            </el-table-column>

            <el-table-column width="60">
              <template slot-scope="scope">
                  <el-tooltip v-if="scope.row.DownloadUrl == null" class="item" effect="dark" content="When your invoice is ready, you can download it here." placement="top">
                    <el-button type="warning" icon="fas fa-hourglass" size="small" circle @click="true"></el-button>
                  </el-tooltip>
                  <el-tooltip v-else class="item" effect="dark" content="Download invoice" placement="top">
                    <el-button type="info" icon="fas fa-download" size="small" circle @click="download(scope.row.DownloadUrl)"></el-button>
                  </el-tooltip>
              </template>
            </el-table-column>

          </el-table>

        </div>
      </div>
    </template>

    <template v-if="activeTab == 'paymentMethods' && organization != null">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{paymentMethods.Items.filter(x => x.Organization.Id == organization.ID).length}} payment method(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-credit-card" @click="cardAddOrUpdateModal = true" round>Add payment method</el-button>
          </div>
        </div>

        <div class="activity-table">

          <NoData v-if="paymentMethods.Items.filter(x => x.Organization.Id == organization.ID).length == 0" />

          <el-table :data="paymentMethods.Items.filter(x => x.Organization.Id == organization.ID)" v-if="paymentMethods.Items.filter(x => x.Organization.Id == organization.ID).length > 0">

            <el-table-column label="Card">
              <template slot-scope="scope">
                <span><b>{{ scope.row.PaymentMethod.Bin.substr(0,4) }} {{ scope.row.PaymentMethod.Bin.substr(4,6) }}</b>** **** ****</span>
              </template>
            </el-table-column>

            <el-table-column label="Family" width="220">
              <template slot-scope="scope">
                <span>{{ scope.row.PaymentMethod.Family}}</span>
              </template>
            </el-table-column>

            <el-table-column label="Status" width="150">
              <template slot-scope="scope">
                <span class="badge green" v-if="!scope.row.PaymentMethod.HasError">Active</span>
                <span class="badge danger" v-if="scope.row.PaymentMethod.HasError">Error</span>
              </template>
            </el-table-column>

            <el-table-column width="160">
              <template slot-scope="scope">
                <el-row>
                  <el-tooltip v-if="!scope.row.PaymentMethod.IsPrimary" class="item" effect="dark" content="Set as primary" placement="top">
                    <el-button type="info" icon="fas fa-check" size="small" circle @click="makePrimary(scope.row.PaymentMethod.Id)" :loading="loading == 'primary-' + scope.row.PaymentMethod.Id"></el-button>
                  </el-tooltip>
                  <el-tooltip v-else class="item" effect="dark" content="Your primary payment method" placement="top">
                    <el-button type="info" size="small" round>Primary</el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                    <el-button type="danger" icon="fas fa-trash" size="small" circle @click="deletePaymentMethod(scope.row.PaymentMethod.Id)" :loading="loading == 'delete-' + scope.row.PaymentMethod.Id"></el-button>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>

          </el-table>

        </div>

      </div>
    </template>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" title="Add payment method" :visible.sync="cardAddOrUpdateModal" center modal-append-to-body destroy-on-close>
      <CardAddOrUpdateModal @closeDialog="cardAddOrUpdateModal = false"  v-if="cardAddOrUpdateModal"/>
    </el-dialog>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import CardAddOrUpdateModal from './CardAddOrUpdateModal.vue';
import moment from "moment/min/moment-with-locales";
export default {
    name: 'BillingIndex',
    components: {
      Breadcrumb,
      CardAddOrUpdateModal,
    },

    data(){
      return {
        moment,
        meta: {
          title: 'Billing',
          desc:'You can see your invoices here.'
        },
        loading:null,
        cardAddOrUpdateModal: false,
        activeTab: 'invoices'
      }
    },
    mounted() {
      this.$setMeta(this.meta);
      this.getData();

      if(this.$route.query.action)
      {
        if(this.$route.query.status == "success")
        {
          this.$message.success("Payment method added successfully.");
          this.activeTab = 'paymentMethods';
        }
        else
        {
          if(this.$route.query.message)
          {
            this.$message.info(this.$route.query.message);
          }
          
        }
      }
    },
    methods: {
      download(url) {
        window.open(url, "_blank");
      },
      async getData() {
        await this.$store.dispatch("getPaymentMethods");
        await this.$store.dispatch("getSubscriptionHistory", {PageSize:100, Page:1});
      },
      async makePrimary(id)
      {
        this.loading = 'primary-' + id;

        await this.$store.dispatch("makePrimaryPaymentMethod", { Id: id });
        await this.$store.dispatch("getPaymentMethods");

        this.loading = null;
      },
      async deletePaymentMethod(id)
      {
        this.loading = 'delete-' + id;

        this.$confirm('You are about to delete a payment method. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("deletePaymentMethod", { Id: id });
        await this.$store.dispatch("getPaymentMethods");
        }).catch(() => {
            
        });

     

        this.loading = null;
      },
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      paymentMethods() {
        return this.$store.getters.getPaymentMethods;
      },
      subscriptionHistory() {
        return this.$store.getters.getSubscriptionHistory;
      }
    }
}
</script>
