<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">
        <div class="filter-item" style="max-width:220px;">
            <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => {
              filter.StartDate = dates.StartDate;
              filter.EndDate = dates.EndDate
            }" />
          </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager') && timesheet && timesheet.some(x => x.Employee.Blocked == true)">
          <el-tooltip class="item" effect="dark" content="Toggle on to show blocked employees" placement="top">
            <el-switch v-model="filter.IncludeBlockedEmployees" active-text=""></el-switch>
          </el-tooltip>
        </div>

      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!timesheet">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" v-if="timesheet">

      <NoData v-if="timesheet.length == 0" />
      <div class="timesheet-container" v-if="timesheet.length > 0">
        

        <el-table :data="timesheet.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true)" style="width: 100%" ref="tab" @row-click="expand" :row-class-name="'cursor-pointer'" v-loading="loading == 'getData'">
          
          <el-table-column label="Employee" min-width="250">
            <template slot-scope="scope">
              <EmployeeTablePreview :employee="scope.row.Employee" />
            </template>
          </el-table-column>
          
          <el-table-column label="Clock-in avg." class-name="text-center" width="125">
            <template slot-scope="props">
              
              <span class="badge cursor-pointer" v-if="$sum(props.row.Timesheet, 'ClockInMins') > 0">{{ $parseTimeFormat(parseInt($sum(props.row.Timesheet, "ClockInMins") / props.row.Timesheet.filter(x => x.ClockInMins != 0).length)) }}</span>

              <span class="badge cursor-pointer" style="opacity:.3" v-else>No data</span>
            </template>
          </el-table-column>

          <el-table-column label="Clock-out avg." class-name="text-center" width="125">
            <template slot-scope="props">
              <span class="badge cursor-pointer" v-if='$sum(props.row.Timesheet, "ClockOutMins") > 0'>{{ $parseTimeFormat(parseInt($sum(props.row.Timesheet, "ClockOutMins") / props.row.Timesheet.filter(x => x.ClockOutMins != 0).length)) }}</span>
              <span class="badge cursor-pointer" style="opacity:.3" v-else>No data</span>
            </template>
          </el-table-column>

          <el-table-column label="Activity level" class-name="text-center" width="125">
            <template slot-scope="props">
              <span class="badge cursor-pointer" v-if="$sum(props.row.Timesheet, 'Working') == 0" style="opacity: .3;">
                No data
              </span>
              
              <span class="badge cursor-pointer" :class="$getActivityLevelBadge($percentage(($sum(props.row.Timesheet, 'ActivityLevel') / $sum(props.row.Timesheet, 'Working')), 60))" v-else>
              %
              {{ 
                $percentage(($sum(props.row.Timesheet, "ActivityLevel") / $sum(props.row.Timesheet, "Working")), 60) 
              }}
              </span>
            </template>
          </el-table-column>


          <el-table-column label="Working" class-name="text-center" width="125">
            <template slot-scope="props">
              <el-tooltip class="item" effect="dark" :content="'Expected working time difference is ' + $parseTime($sum(props.row.Timesheet, 'ExpectedWorkHoursDiff'), true)" placement="top">
                <span class="badge cursor-pointer" :style="$sum(props.row.Timesheet, 'Working') == 0 ? 'opacity:.3' : ''" >
                  {{ $parseTime($sum(props.row.Timesheet, "Working"), true) }}
                  <i v-if="$sum(props.row.Timesheet, 'ExpectedWorkHoursDiff') > 0" class="fas fa-angle-double-up text-success"></i>
                  <i v-else class="fas fa-angle-double-down text-danger"></i>
              </span>
              </el-tooltip>
            </template>
          </el-table-column>
          
          <el-table-column label="Break" class-name="text-center" width="125">
            <template slot-scope="props">
              <span class="badge cursor-pointer" :style="$sum(props.row.Timesheet, 'OnBreak') == 0 ? 'opacity:.3' : ''">{{ $parseTime($sum(props.row.Timesheet, "OnBreak"), true) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Idle" class-name="text-center" width="125">
            <template slot-scope="props">
              <span class="badge cursor-pointer" :style="$sum(props.row.Timesheet, 'Idle') == 0 ? 'opacity:.3' : ''">{{ $parseTime($sum(props.row.Timesheet, "Idle"), true) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Total" class-name="text-center" prop="id" width="125">
            <template slot-scope="props">
              <span class="badge cursor-pointer" :style="$sum(props.row.Timesheet, 'TotalMins') == 0 ? 'opacity:.3' : ''">{{ $parseTime($sum(props.row.Timesheet, "TotalMins"), true) }}</span>
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">

              <div class="timesheet-days-items-container" :id="'days-' + props.row.Employee.Id">
                <div class="card no-leader p-3 day-item cursor-pointer" :class="ts.isActive ? '' : 'bg-yellow'" v-for="(ts,index) in props.row.Timesheet" :key="props.row.Employee.Id + index" @click.stop='$store.commit("setProfileEmployeeId", { id: props.row.Employee.Id, date:ts.Date, showDrawer: true })'>
                  
                  <div class="day-header">
                    <span>{{ moment(ts.Date).format('dddd')  }}</span>
                    <span>{{ moment(ts.Date).format('DD MMM')  }}</span>
                  </div>

                  <div class="clock-section" :style="ts.ClockIn == null ? 'opacity:.3' : ''">
                    <el-tooltip class="item" effect="dark" content="Clock-in / clock-out" placement="top">
                        <span><i class="fas fa-clock"></i>
                            {{ ts.ClockIn != null ? moment(ts.ClockIn).format('HH:mm') : "--:--" }} 
                            / 
                            {{ ts.ClockOut != null ? moment(ts.ClockOut).format('HH:mm') : "--:--" }} 
                        </span>
                    </el-tooltip>
                  </div>


                  <el-tooltip class="item" effect="dark" :content="'Expected working time difference is ' + $parseTime(ts.ExpectedWorkHoursDiff, true)" placement="top">
                 
                    <div class="stat-item green" :style="ts.Working == 0 ? 'opacity:.3' : ''">
                    <span>
                      <i class="fas fa-bolt"></i> Work time
                    </span>
                    <span>
                      <i v-if="ts.ExpectedWorkHoursDiff > 0" class="fas fa-angle-double-up text-success"></i>
                      <i v-else class="fas fa-angle-double-down text-danger"></i>
                      {{ $parseTime(ts.Working, true) }}</span>
                  </div>

                  </el-tooltip>

                  

                  <div class="stat-item blue" :style="ts.OnBreak == 0 ? 'opacity:.3' : ''">
                    <span>
                      <i class="fas fa-mug-hot"></i> Break
                    </span>
                    <span>{{ $parseTime(ts.OnBreak, true)}}</span>
                  </div>

                   <div class="stat-item orange" :style="ts.Idle == 0 ? 'opacity:.3' : ''">
                    <span>
                      <i class="fas fa-bed"></i> Idle
                    </span>
                    <span>{{ $parseTime(ts.Idle, true) }}</span>
                  </div>

                  <div class="stat-item purple" :style="ts.Working == 0 ? 'opacity:.3' : ''">
                    <span>
                      <i class="fas fa-keyboard"></i> Activity level
                    </span>
                    <span v-if="ts.Working > 0" :style="'color:' + $getAcitivtyLevelBadgeColor($percentage(ts.ActivityLevel, 60))">%{{ $percentage((ts.ActivityLevel / ts.Working), 60) }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="total-section">
                    <span>Total time</span>
                    <span>{{ $parseTime(ts.TotalMins, true) }}</span>
                  </div>                 

                </div>
              </div>

            </template>
          </el-table-column>  
        </el-table>
      </div>
    </div>


    <div class="d-flex mb-5 justify-content-center" v-if="timesheet && sub">
      <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
        Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
      </el-button>
      <div class="d-flex align-items-center">
        <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import moment from "moment/min/moment-with-locales";

export default {
    name: 'TimesheetIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker
    },

    data(){
      return {
        moment,
        loading:null,
        meta: {
          title: 'Timesheet',
          desc: 'You can reach your timesheet insights here.'
        },
        filter: {
          TeamId:null,
          EmployeeId:null,
          DateFilter:3,
          StartDate:null,
          EndDate:null,
          IncludeBlockedEmployees:false
        },
        timesheet:null
      }
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("getTimesheet", this.filter)
        this.calculate();
        this.loading = null;
      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("timesheetExport", this.filter);
        if (res != null) {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.Id == row.Id))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.Id != row.Id);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);

           this.$nextTick(() => {
              var element = document.getElementById('days-' + row.Employee.Id);
              element.scrollLeft = element.scrollWidth
            });
          
        }
        //this.$refs.tab.store.states.expandRows = (isExpanded ? [row] : [])
      },
      calculate() {
        var result = [];
        var data = this.$store.getters.getTimesheet;
        if(!data || data.Timesheet.length == 0)
        {
          this.timesheet = result;
          return result;
        }

        for (let i = 0; i < data.Timesheet[0].GetWorkTimesInsightsQueryResult.Items.length; i++) {
          const element = data.Timesheet[0].GetWorkTimesInsightsQueryResult.Items[i];
          result.push({
            Id: element.Employee.Id,
            Employee: element.Employee,
            ClockIn: "1",
            ClockOut: null,
            ClockInMs:0,
            ClockOutMs:0,
            Idle: 55,
            OnBreak: 25,
            Working: 35,
            TotalMins: 633,
            Timesheet: []
          });
        }

        for (let i = 0; i < data.Timesheet.length; i++) {
          const timesheet = data.Timesheet[i];
          for (let ii = 0; ii < timesheet.GetWorkTimesInsightsQueryResult.Items.length; ii++) {
            const data = timesheet.GetWorkTimesInsightsQueryResult.Items[ii];
            var index = result.findIndex(x => x.Id == data.Employee.Id);
            if(index != -1)
            {
              var ts = {
                Date:timesheet.StartDate,
                Id:null,
                ClockIn: data.ClockIn,
                ClockOut: data.ClockOut,
                ClockInMs: data.ClockIn != null ? new Date(data.ClockIn).getTime() : 0,
                ClockInMins: data.ClockIn != null ? (((new Date(data.ClockIn).getHours() * 60) + new Date(data.ClockIn).getMinutes())) : 0,
                ClockOutMs: data.ClockOut != null ? new Date(data.ClockOut).getTime() : 0,
                ClockOutMins: data.ClockOut != null ? (((new Date(data.ClockOut).getHours() * 60) + new Date(data.ClockOut).getMinutes())) : 0,
                Idle: data.Idle,
                OnBreak: data.OnBreak,
                Working: data.Working,
                TotalMins: data.TotalMins,
                ActivityLevel: data.ActivityLevel,
                ExpectedWorkHoursDiff:data.ExpectedWorkHoursDiff,
                isActive:true
              };

              var dayOfWeek = new Date(ts.Date).getDay();
              if(dayOfWeek == 0)
              {
                dayOfWeek = 7;
              }

              ts.isActive = this.trackSettings.WorkingDays.some(x => x == dayOfWeek);
              result[index].Timesheet.push(ts)
            }
          }
        }
        this.timesheet = result;
      }
    },
    async mounted() {
      this.$setMeta(this.meta);

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      await this.getData();
      await this.$store.dispatch("getSubscription");
    },
    watch: {
      'filter.StartDate'() {
         this.getData();
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }
        this.getData();
      },
      'filter.EmployeeId'() {
        this.getData();
      },
    },
    computed: {
      employees() {
        return this.$store.getters.getEmployees
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
      account() {
            return this.$store.getters.getAccount;
        },
        organization() {
            return this.account.CurrentOrganization;
        },
        trackSettings() {
            return this.organization.TrackSettings;
        },
    }
}
</script>
