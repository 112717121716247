<template>
    <div class="sidebar-menu">

        <el-menu :default-openeds="activeMenu" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :mode="'vertical'">
            <template v-if="organization != null">
                <el-submenu index="dashboard" v-if="$isInRole('Owner,Coowner,Manager')">
                    <template slot="title">
                        <span>Dashboard</span>
                    </template>

                    <el-menu-item index="overview">
                        <router-link :to="{ name: 'overview' }" class="sidebar-item">
                            <i class="fas fa-chart-line"></i>Overview
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="team">
                        <router-link :to="{ name: 'team' }" class="sidebar-item">
                            <i class="fas fa-layer-group"></i>Team
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="tracking">
                    <template slot="title">
                        <span>Tracking</span>
                    </template>

                    <el-menu-item index="my-activities">
                        <router-link :to="{ name: 'my-activities' }" class="sidebar-item">
                            <i class="fas fa-id-card"></i>My activities
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="screenshots" v-if="$isInRole('Owner,Coowner,Manager') || trackSettings.AllowEmployeeToSeeTheirScreenCapture">
                        <router-link :to="{ name: 'screenshots' }" class="sidebar-item">
                            <i class="fas fa-images"></i>Screenshots
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="timelapse-videos" v-if="$isInRole('Owner,Coowner,Manager') || trackSettings.AllowEmployeeToSeeTheirTimelapseVideo">
                        <router-link :to="{ name: 'timelapse-videos' }" class="sidebar-item">
                            <i class="fas fa-photo-video"></i>Timelapse videos
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="timesheet" v-if="$isInRole('Owner,Coowner,Manager') || trackSettings.AllowEmployeeToSeeTheirTimesheet">
                        <router-link :to="{ name: 'timesheet' }" class="sidebar-item">
                            <i class="fas fa-history"></i>Timesheet
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="manual-time-entries" v-if="$isInRole('Owner,Coowner') || trackSettings.AllowEmployeesToAddManualTimeEntries">
                        <router-link :to="{ name: 'manual-time-entries' }" class="sidebar-item">
                            <i class="fas fa-stopwatch"></i> Manual time entries
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="review-apps" v-if="$isInRole('Owner,Coowner') || ($isInRole('Manager') && trackSettings.AllowManagersToSeeAppReviews)">
                        <router-link :to="{ name: 'review-apps' }" class="sidebar-item">
                            <i class="fas fa-check-circle"></i>Review apps <span class="ml-1 badge yellow" v-if="unreviewed && unreviewed.TotalCount > 0">{{ unreviewed.TotalCount }}</span>
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="task-tracking" v-if="trackSettings.EnableProjectManagement">
                    <template slot="title">
                        <span>Task tracking</span>
                    </template>

                    <el-menu-item index="tasks">
                        <router-link :to="{ name: 'tasks' }" class="sidebar-item">
                            <i class="fas fa-tasks"></i> Tasks
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="projects" v-if="$isInRole('Owner,Coowner') || ($isInRole('Manager') && trackSettings.AllowManagersToCreateAndEditProjects)">
                        <router-link :to="{ name: 'projects' }" class="sidebar-item">
                            <i class="fas fa-project-diagram"></i> Projects
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="customers" v-if="$isInRole('Owner,Coowner') || ($isInRole('Manager') && trackSettings.AllowManagersToCreateAndEditCustomers)">
                        <router-link :to="{ name: 'customers' }" class="sidebar-item">
                            <i class="fas fa-user-tie"></i> Customers
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="leave-management" v-if="trackSettings.EnableLeaveManagement">
                    <template slot="title">
                        <span>Leave management</span>
                    </template>

                    <el-menu-item index="leave-requests">
                        <router-link :to="{ name: 'leave-requests' }" class="sidebar-item">
                            <i class="fas fa-suitcase-rolling"></i> Leave requests <span class="badge badge-sm yellow ml-2">BETA</span>
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="leave-rights" v-if="$isInRole('Owner,Coowner')">
                        <router-link :to="{ name: 'leave-rights' }" class="sidebar-item">
                            <i class="fas fa-globe-europe"></i> Leave rights
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="insights" v-if="$isInRole('Owner,Coowner,Manager')">
                    <template slot="title">
                        <span>Insights</span>
                    </template>

                    <el-menu-item index="work-times">
                        <router-link :to="{ name: 'work-times' }" class="sidebar-item">
                            <i class="fas fa-business-time"></i>Work times
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="productivity">
                        <router-link :to="{ name: 'productivity' }" class="sidebar-item">
                            <i class="fas fa-bolt"></i>Productivity
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="task-insights" v-if="trackSettings.EnableProjectManagement">
                        <router-link :to="{ name: 'task-insights' }" class="sidebar-item">
                            <i class="fas fa-meteor"></i>Task insights
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="apps-summary">
                        <router-link :to="{ name: 'apps-summary' }" class="sidebar-item">
                            <i class="fas fa-shapes"></i>Apps summary
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="work-notes-insights" v-if="trackSettings.WorkNotes != null && trackSettings.WorkNotes.length > 0">
                        <router-link :to="{ name: 'work-notes-insights' }" class="sidebar-item">
                            <i class="fas fa-meteor"></i>Work notes
                        </router-link>
                    </el-menu-item>


                </el-submenu>

                <el-submenu index="cost-management" v-if="trackSettings.EnableCostManagement && $isInRole('Owner,Coowner')">
                    <template slot="title">
                        <span>Cost management</span>
                    </template>

                    <el-menu-item index="invoices">
                        <router-link :to="{ name: 'invoices' }" class="sidebar-item">
                            <i class="fas fa-file-invoice"></i>Invoices
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="payroll-calculator">
                        <router-link :to="{ name: 'payroll-calculator' }" class="sidebar-item" >
                            <i class="fas fa-calculator"></i>Payroll calculator
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                <el-submenu index="organization" v-if="$isInRole('Owner,Coowner')">
                    <template slot="title">
                        <span>Organization</span>
                    </template>

                    <el-menu-item index="employees">
                        <router-link :to="{ name: 'employees' }" class="sidebar-item sidebar-avatar">
                            <i class="fas fa-user-friends"></i>Employees
                        </router-link>
                    </el-menu-item>

                    <el-menu-item index="settings">
                        <router-link :to="{ name: 'settings' }" class="sidebar-item sidebar-avatar">
                            <i class="fas fa-building"></i>Settings
                        </router-link>
                    </el-menu-item>
                </el-submenu>

                

                <!-- <el-menu-item index="4">
                    <i class="el-icon-setting"></i>
                    <span>Navigator Four</span>
                </el-menu-item> -->
            
            </template>
            <template v-else>
                <el-submenu index="organization">
                    <template slot="title">
                        <span>Organization</span>
                    </template>

                    <el-menu-item index="create-organization">
                        <router-link :to="{ name: 'create-organization' }" class="sidebar-item sidebar-avatar">
                            <i class="fas fa-plus"></i>Create an organisation
                        </router-link>
                    </el-menu-item>
                </el-submenu>
            </template>

            <el-submenu index="account">
                <template slot="title">
                    <span>Account</span>
                </template>

                <el-menu-item index="account-settings">
                    <router-link :to="{ name: 'account-settings' }" class="sidebar-item sidebar-avatar">
                        <i class="fas fa-user-cog"></i>Settings
                    </router-link>
                </el-menu-item>

                <el-menu-item index="billing" v-if="$isInRole('Owner,Coowner')">
                    <router-link :to="{ name: 'billing' }" class="sidebar-item sidebar-avatar">
                        <i class="fas fa-file-invoice"></i>Billing
                    </router-link>
                </el-menu-item>

                <el-menu-item index="affiliate">
                    <router-link :to="{ name: 'affiliate' }" class="sidebar-item sidebar-avatar">
                        <i class="fas fa-coins"></i>Affiliate
                    </router-link>
                </el-menu-item>
            </el-submenu>

        </el-menu>
    </div>
</template>


<script>
import Vue from 'vue';
const io = require("socket.io-client");

export default {
    name: 'SideBar',
    components: {

    },
    data(){
        return {
            timeEntries:null,
            //socket:null,
            subscribedChannels: [],
            activeMenu: ['dashboard','tracking','task-tracking','leave-management','insights','cost-management','organization','account'],
        }
    },
    created() {
        this.checkRoute();
        this.loadMenuState();

        if(this.isDemo)
        {
            window.onbeforeunload = function ()
            {
                return '';
            };

            window.onunload = function ()
            {
                localStorage.removeItem("token");
            };
        }
    },
    mounted() {
        if(this.$isInRole("Owner,Coowner,Manager"))
        {
            this.$store.dispatch("unreviewed", {
                Page:1,
                PageSize:5
            });
        }

        if(this.subscription == null || !this.subscription.IsLifetime)
        {
            this.$tawkInit();
        }

        Vue.prototype.$socket = io("https://socket.useworktivity.com", {
            transports: ["websocket"],
            upgrade: false,
            query: {
                token: localStorage.getItem("token")
            },
            reconnectionDelay: 500,
            reconnectionDelayMax: 1000,
            reconnectionAttempts: 1000
        });

        this.$socket.on('connected', (data) => {
            console.log("socket connection initialized");
            for (let i = 0; i < this.subscribedChannels.length; i++) {
                const channel = this.subscribedChannels[i];
                Vue.prototype.$socket.emit("subscribe", {
                    channel: channel,
                    token: localStorage.getItem("token") != null ? localStorage.getItem("token") : null
                });
            }

            if(this.subscribedChannels.length == 0)
            {
                this.$socket.emit("subscribe", {
                    channel: "presence-user-" + this.account.User.ID,
                    token: localStorage.getItem("token")
                });
            }

            if(this.account.CurrentOrganization != null)
            {
                this.$socket.emit("subscribe", {
                    channel: "presence-organization-" + this.account.CurrentOrganization.ID,
                    token: localStorage.getItem("token")
                });

                var currentEmplooyee = this.account.Employings.Items.find(x => x.OrganizationId == this.account.CurrentOrganization.ID);
                if(currentEmplooyee != null)
                {
                    this.$socket.emit("subscribe", {
                        channel: "presence-employee-" + currentEmplooyee.ID,
                        token: localStorage.getItem("token")
                    });
                }
            }
        });

        this.$socket.on("disconnect", (data) => {
            console.log("socket disconnected");
        });

        this.$socket.on("subscribe_success", data => {
            console.log("subscribe_success", data);
            if (!this.subscribedChannels.some(x => x == data)) {
                this.subscribedChannels.push(data);
            }
        });

        this.$socket.on("unsubscribe_success", data => {
            if (data != null && data != undefined) {
                this.subscribedChannels = this.subscribedChannels.filter(x => x != data);
            }
        });

        this.$socket.on("error", function() {
            console.log("socket error");
        });

        Vue.prototype.$socket.on("connect_error", function() {
            console.log("socket connnect_error");
            setTimeout(() => {
                Vue.prototype.$socket.connect();
            }, 1000);
        });

        //
        // const reloaded = localStorage.getItem('reloaded');
        // const isSupported = this.$OneSignal.Notifications.isPushSupported();
        // if (reloaded !== 'true') {
        //     if (isSupported) {
        //         this.$OneSignal.User.PushSubscription.optIn();
        //     }
        //     localStorage.setItem('reloaded', 'true');
        // }
        
        this.$gtag.set({
            'user_id': this.account.User.ID,
        });
    },
    methods: {
        handleOpen(key, keyPath) {
            this.updateMenuState(key, true); // Menü seçeneğini ekle
        },
        handleClose(key, keyPath) {
            this.updateMenuState(key, false); // Menü seçeneğini kaldır
        },
        updateMenuState(key, isOpen) {
            let menuState = localStorage.getItem('menuState');
            menuState = menuState ? JSON.parse(menuState) : [];

            if (isOpen && !menuState.includes(key)) {
                menuState.push(key);
            } else if (!isOpen && menuState.includes(key)) {
                const index = menuState.indexOf(key);
                if (index > -1) {
                menuState.splice(index, 1);
                }
            }

            localStorage.setItem('menuState', JSON.stringify(menuState));
        },
        loadMenuState() {
            const menuState = localStorage.getItem('menuState');
            if (menuState) {
                this.activeMenu = JSON.parse(menuState);
            } else {
                this.activeMenu.forEach(element => {
                    this.updateMenuState(element, true);
                    console.log(element);
                });
            }
        },
        checkRoute() {
            var name = this.$route.name;
            if(this.organization == null)
            {
                if(name != "create-organization" && name != "account-settings" && name != "affiliate")
                {
                    this.$router.push({name:"create-organization"});
                }
            }
            else if((this.organization.Subscription == null || new Date(this.organization.Subscription.EndDate) < new Date()) && 
                !["manage-subscription","account-settings","affiliate", "billing","settings","employees"].some(x => x == name)
                )
            {
                if(this.$isInRole('Owner,Coowner'))
                {
                    this.$router.push({ name: "manage-subscription" }).catch(() => {});
                }
                else
                {
                    this.$router.push({ name: "subscription-over" }).catch(() => { });
                }
            }
            else if(['settings','employees', 'manage-subscription'].some(x => x == name) && !this.$isInRole('Owner,Coowner'))
            {
                this.$router.push({name:"my-activities"});
            }
            else if(['overview','team','productivity','work-times','apps-summary', 'review-apps'].some(x => x == name) && this.$isInRole('Employee'))
            {
                this.$router.push({name:"my-activities"}).catch(() => { });
            }
        }
    },
    computed: {
        isDemo() {
            return this.organization != null && this.organization.OrganizationNo == '149650';
        },
        account() {
            return this.$store.getters.getAccount;
        },
        organization() {
            return this.account.CurrentOrganization;
        },
        trackSettings() {
            return this.organization.TrackSettings;
        },
        subscription() {
            return this.organization.Subscription;
        },
        getEnums() {
            return this.$store.getters.getEnums;
        },
        unreviewed() {
            return this.$store.getters.getUnreviewed;
        },
    },
    watch: {
        '$route.name'() {
            this.checkRoute();
            this.$store.dispatch('getShowMenu', false);
        },
    }
}
</script>

